import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface MetaData {
  pageTitle: string;
  description: string;
  keywords: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly router: Router
  ) {}

  public updateTags(metaData: MetaData): void {
    this.setPageTitle(metaData.pageTitle);
    this.setKeywords(metaData.keywords);
    this.setDescription(metaData.description);
    this.setImage(metaData.image);
    this.setUrl();
  }

  private setPageTitle(title: string): void {
    this.titleService.setTitle(title);

    this.metaService.updateTag({ name: 'og:title', content: title });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
  }

  private setKeywords(keywords: string): void {
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  private setDescription(description: string): void {
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'og:description', content: description });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
  }

  private setImage(image: string): void {
    this.metaService.updateTag({ name: 'og:image', content: image });
    this.metaService.updateTag({ name: 'twitter:image', content: image });
  }

  private setUrl(): void {
    const baseUrl = environment.baseUrl.substring(0, environment.baseUrl.length - 1);
    const fullUrl = `${baseUrl}${this.router.url}`;

    this.metaService.updateTag({ name: 'og:url', content: fullUrl });
    this.metaService.updateTag({ name: 'twitter:url', content: fullUrl });
  }
}
